<template>
  <div class="p-5">
    <div class="flex items-center justify-between">
      <p class="text-2xl font-[600] text-[#004466]">
        {{ module }}-modul IMTIHON
      </p>

      <div class="flex items-center gap-2">
        <div v-if="exam?.for_date" class="flex items-center gap-2">
          <p class="exam-timer">
            {{ DateTime.fromISO(exam?.for_date).toFormat("dd-MMM-yyyy") }}
          </p>
        </div>
        <div v-if="isExamToday" class="flex items-center gap-2">
          <p class="exam-timer">
            {{ examTimer || "secundomer" }}
          </p>
        </div>
        <button
          v-if="exam"
          @click="startExam"
          :disabled="isLoading || !exam"
          class="flex items-center gap-1 bg-orangeThin text-white px-2 py-1 text-sm rounded active:bg-orange-400 disabled:opacity-50"
        >
          <i v-if="!isLoading" class="fa-solid fa-play"></i>
          <i v-else class="fa-solid fa-spinner animate-spin"></i>
          {{ exam?.for_date ? "Qayta boshlash" : "Boshlash" }}
        </button>
      </div>
    </div>
    <el-divider />
    <div v-if="!loading">
      <div v-if="exam">
        <p class="text-xl font-[600] capitalize">
          {{ exam.title?.uz || exam.title?.ru }}
        </p>
        <div class="mt-2" v-if="examThemes?.exam_themes?.length">
          <div
            v-for="(theme, i) in examThemes?.exam_themes"
            :key="theme.id"
            class="bg-gray-50 p-2 rounded-md my-2"
          >
            <p class="text-xl font-[600]">{{ i + 1 }}.{{ theme?.title }}</p>

            <div class="grid grid-cols-2 gap-5 mt-5">
              <div v-if="theme?.project?.requirements?.length">
                <p class="text-sm font-[600]">Requirements</p>
                <div
                  v-for="(req, i) in theme?.project?.requirements"
                  :key="req.id"
                >
                  <p>{{ i + 1 }}.{{ req?.uz }}</p>
                </div>
              </div>

              <div>
                <div v-if="theme?.project?.example_file?.length">
                  <a
                    :href="medialink + theme?.project?.example_file"
                    target="_blank"
                    class="text-blue-500 underline"
                    download
                    >Imtihon fayli</a
                  >
                </div>
                <div
                  v-if="theme?.project?.example_picture?.length"
                  class="mt-2"
                >
                  <el-image
                    :src="medialink + theme?.project?.example_picture"
                    fit="cover"
                    class="w-[200px] h-[200px]"
                    :preview-teleported="true"
                    :preview-src-list="[
                      medialink + theme?.project?.example_picture,
                    ]"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="mt-5">
          <el-empty description="Mavzular qo'shilmagan" />
        </div>

        <div v-if="groupId">
          <StudentsExamineTable :group_id="groupId" :module="module" />
        </div>
      </div>
      <el-empty v-else description="Imtihon topilmadi" />
    </div>
    <div v-else>
      <el-skeleton :rows="5" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch, onUnmounted } from "vue";
import api from "@/apiConfig/api";
import { ElMessage } from "element-plus";
import { DateTime } from "luxon";
import StudentsExamineTable from "@/modules/Teachers/pages/GroupExamineDate/components/StudentsExamineTable.vue";
import { medialink } from "@/apiConfig/medialink";

const props = defineProps({
  groupId: {
    type: [String, Number],
    required: false,
  },
  module: {
    type: [String, Number],
    required: false,
  },
  courseId: {
    type: [String, Number],
    required: false,
  },
});

const isLoading = ref(false);
const exam = ref(null);
const examTimer = ref("");
const loading = ref(false);
const groupInfo = ref(null);
const examThemes = ref(null);
const timerInterval = ref(null);

const startExam = async () => {
  isLoading.value = true;
  try {
    await api.post("/courses/group_exam", {
      group_id: props.groupId,
      module: props.module,
    });
    ElMessage.success("Exam boshlandi");
  } catch (err) {
    ElMessage.error("Exam boshlashda xatolik");
  } finally {
    isLoading.value = false;
    getExam();
    startExamTimer();
  }
};

const getExam = async () => {
  loading.value = true;
  try {
    const response = await api.get("/courses/group_exam", {
      params: { module: props.module, group_id: props.groupId },
    });
    exam.value = response.data;
  } catch (err) {
    console.error("Error fetching exam:", err);
  } finally {
    loading.value = false;
  }
};

const getGroupInfo = async () => {
  try {
    const response = await api.get(`/groups/${props.groupId}`);
    groupInfo.value = response.data;
  } catch (err) {
    console.error("Error fetching group info:", err);
  }
};

const getExamThemes = async () => {
  try {
    const response = await api.get("/courses/exam_themes", {
      params: { course_id: groupInfo.value.category_id, module: props.module },
    });
    examThemes.value = response.data;
  } catch (err) {
    console.error("Error fetching exam themes:", err);
  }
};

const isExamToday = computed(() => {
  if (!exam.value) return false;
  const examTime = DateTime.fromISO(exam.value?.start_time);
  return examTime.hasSame(DateTime.now(), "day");
});

const startExamTimer = () => {
  if (isExamToday.value) {
    timerInterval.value = setInterval(() => {
      const examTime = DateTime.fromISO(exam.value?.end_time);
      const localTime = DateTime.now();

      if (examTime < localTime) {
        examTimer.value = "Imtihon vaqti tugadi";
        clearInterval(timerInterval.value);
        return;
      }

      examTimer.value = examTime.diff(localTime).toFormat("hh:mm:ss");
    }, 1000);
  }
};

onMounted(async () => {
  await getGroupInfo();
  getExam();
  getExamThemes();
});

onUnmounted(() => {
  clearInterval(timerInterval.value);
});

watch(isExamToday, () => {
  if (isExamToday.value) {
    startExamTimer();
  }
});
</script>

<style>
.exam-timer {
  border: 1px solid var(--orangeThin);
  color: var(--orangeThin);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  min-width: 100px;
  text-wrap: nowrap;
  min-height: 30px;
}
</style>
