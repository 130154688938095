<template>
  <!-- <div class="sidebar__link-wrapper">
    <router-link to="/groups-opening" class="sidebar__link">
      <i class="fa-solid fa-right-left text-xl mx-auto"></i>
      <p>{{ $t("Sidebar.managing") }}</p>
    </router-link>
  </div> -->
  <!-- <div class="sidebar__link-wrapper">
    <router-link to="/admin-new-leads" class="sidebar__link">
      <i class="fa-solid fa-user-plus text-xl mx-auto"></i>
      <p>Leads</p>
    </router-link>
  </div> -->
  <div class="sidebar__link-wrapper">
    <router-link to="/admin-form" class="sidebar__link">
      <i class="fa-brands fa-wpforms text-xl mx-auto"></i>
      <p>Forma</p>
    </router-link>
  </div>
  <div class="sidebar__link-wrapper">
    <router-link to="/admin-students" class="sidebar__link">
      <i class="fa-solid fa-users text-xl mx-auto"></i>
      <p>{{ $t("Sidebar.students-list") }}</p>
    </router-link>
  </div>
  <div class="sidebar__link-wrapper">
    <router-link to="/admin-groups" class="sidebar__link">
      <i class="fa-solid fa-layer-group text-xl mx-auto"></i>
      <p>{{ $t("Sidebar.groups-list") }}</p>
    </router-link>
  </div>

  <div class="sidebar__link-wrapper">
    <router-link to="/admin-space-shop" class="sidebar__link">
      <i class="fa-solid fa-store text-xl mx-auto"></i>
      <p>{{ $t("Sidebar.space-shop") }}</p>
    </router-link>
  </div>

  <div class="sidebar__link-wrapper">
    <router-link to="/admin-mentors" class="sidebar__link">
      <i class="fa-solid fa-user-tie text-xl mx-auto"></i>
      <p>{{ $t("Sidebar.mentors") }}</p>
    </router-link>
  </div>
  <div class="sidebar__link-wrapper">
    <router-link to="/admin-sms" class="sidebar__link">
      <i class="fa-solid fa-envelope text-xl mx-auto"></i>
      <p>{{ $t("Sidebar.sms") }}</p>
    </router-link>
  </div>

  <div class="sidebar__link-wrapper">
    <router-link to="/admin-coins" class="sidebar__link">
      <i class="fa-solid fa-coins text-2xl"></i>
      <p>Coins</p>
    </router-link>
  </div>
  <div class="sidebar__link-wrapper">
    <router-link to="/admin-test" class="sidebar__link">
      <i class="fa-solid fa-file-code text-2xl"></i>
      <p>Test</p>
    </router-link>
  </div>
</template>
