<template>
  <div v-if="loading">
    <el-skeleton :rows="5" />
  </div>
  <div v-else class="teacher-exam-table overflow-x-auto">
    <table v-if="studentExamResults?.length" class="w-full overflow-x-auto">
      <thead>
        <tr>
          <th>Student</th>
          <th>Imtihon fayllari</th>
          <th>Natija</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(student, i) in studentExamResults" :key="student.id">
          <td class="w-[200px]">{{ i + 1 }}.{{ student.student }}</td>
          <td class="student-exam-files-cell">
            <div v-if="student?.exam?.length">
              <el-collapse>
                <el-collapse-item title="Examine">
                  <div class="w-full overflow-x-auto">
                    <table class="w-full exam-mark-table">
                      <thead>
                        <tr>
                          <th
                            class="bg-gray-100"
                            v-for="th in [
                              'Mavzu',
                              'File',
                              'Link',
                              'Feedback',
                              'Requirements',
                            ]"
                            :key="th"
                          >
                            {{ th }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(examRes, i) in student.exam"
                          :key="examRes.id"
                        >
                          <td class="p-1 w-[200px]">
                            {{ examRes?.theme?.title }}
                          </td>
                          <td class="p-1 w-[60px]">
                            <a
                              v-if="examRes?.answer?.file"
                              :href="medialink + examRes?.answer?.file"
                              target="_blank"
                              class="text-orangeThin underline"
                              >file</a
                            >
                            <span v-else>-</span>
                          </td>
                          <td class="p-1 w-[60px]">
                            <a
                              v-if="examRes?.answer?.link"
                              :href="examRes?.answer?.link"
                              target="_blank"
                              class="text-orangeThin underline"
                              >link</a
                            >
                            <span v-else>-</span>
                          </td>

                          <td class="p-1 w-[400px]">
                            <p>{{ examRes?.answer?.feedback || "-" }}</p>
                          </td>
                          <td class="p-1 w-[400px]">
                            <div
                              v-for="req in examRes?.theme?.project
                                ?.requirements"
                              :key="req.id"
                            >
                              <div
                                class="flex items-center gap-2 justify-center"
                              >
                                <div v-if="student?.overall !== null">
                                  <i
                                    v-if="req.is_applied"
                                    class="fa-solid fa-check-circle text-green-500"
                                  ></i>
                                  <i
                                    v-else
                                    class="fa-solid fa-xmark text-red-500"
                                  ></i>
                                </div>
                                <p>{{ req.uz }}</p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <div v-else>
              <el-tag type="danger"> Imtihon Yuklanmagan </el-tag>
            </div>
          </td>
          <td class="w-[60px]">
            <div v-if="student?.overall === null && student?.exam?.length">
              <el-tag type="warning">
                <i class="fa-solid fa-hourglass-half"></i>
              </el-tag>
            </div>
            <el-tag
              v-else-if="student.exam?.length && student?.overall !== null"
              :type="student?.overall >= 70 ? 'success' : 'danger'"
            >
              {{ student?.overall }}
            </el-tag>
            <span v-else>-</span>
          </td>
        </tr>
      </tbody>
    </table>

    <el-empty v-else description="Studentlar topilmadi" />
  </div>
</template>
<script setup>
import { ref, watch, onMounted } from "vue";
import { getStudentsExamineResults } from "../api/get-students-exam-results-api.js";
import { medialink } from "@/apiConfig/medialink";
import { Loading } from "@element-plus/icons-vue";

const props = defineProps({
  group_id: String,
  module: String,
});

const studentExamResults = ref([]);

const loading = ref(false);

const getExamResMethod = async () => {
  loading.value = true;

  if (!props.group_id || !props.module) return;
  studentExamResults.value = await getStudentsExamineResults(
    props.module,
    props.group_id
  )
    .then((res) => res.data)
    .catch(() => {
      studentExamResults.value = [];
    });

  loading.value = false;
};

onMounted(() => {
  getExamResMethod();
});

watch(
  () => props.module,
  () => {
    getExamResMethod();
  }
);

watch(
  () => props.group_id,
  () => {
    getExamResMethod();
  }
);
</script>
<style lang="scss">
.teacher-exam-table {
  th,
  td {
    padding: 5px;
    border: 1px solid #f3f3f3;
    text-align: center;
    &:first-child {
      text-align: left;
    }
  }
}
</style>
