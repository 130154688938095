import api from "@/apiConfig/api";

export const getStudentsExamineResults = async (module, group_id) => {
  return await api.get("/courses/exam_results", {
    params: {
      module: module,
      group_id: group_id,
    },
  });
};
